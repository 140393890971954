'use client'
import { API_CONFIG } from '../../helper/HttpHelper'
import { CreateForm } from '../../component/Form'
import { useParams } from 'react-router-dom';

function SignUpScreen(){
  const { enterprise_type } = useParams()
  const form = {
      'body': [
          { type: 'FTTitle', display:false, title: 'Sign Up', subtitle: 'Please enter the loan requirement details', field: 'form_title'},
          { type: 'FTLink', mesg1: 'Check your email and click the link below to verify your email',  mesg2:'Click Here', url:'/verifyAccount', display:'hidden'},
          { type: 'FTTextInput', required:true, label: 'Business Name', placeholder: 'eg. Ravi Enterprises', field: 'business_name'},
          { type: 'FTTextInput', required:true, label: 'Email', placeholder: 'eg. ravi@abc.com', field: 'email', error:['Please enter a valid email address'], validator:['isEmail']},
          { type: 'FTTextInput', required:true, label: 'Password', helperText: 'Minimum 8 characters, atleast 1 number, 1 special character, 1 upper case, 1 lower case. ', field: 'password', error:['Please select a stronger password'], validator:['isPasswordValid'], inputType: 'password'},
          { type: 'FTTextInput', required:true, label: 'Confirm Password', placeholder: '', field: 'confirm_password', inputType: 'password', error:['Your password and confirm password does not match'], validator:['isEqual'], validator_fields:[['password']]},
          { type: 'FTButton', title: 'Sign Up', field: 'submit_button'},
          { type: 'FTLink', mesg1: 'Already a member?',  mesg2:'Back to Login', url:'/login'}          
      ],
      'submit':{ 'endpoint': API_CONFIG.API_BASE_URL + 'auth/signUp', 'data': {'clientId': process.env.REACT_APP_AWS_COGNITO_CLIENTID, 'enterprise_type': enterprise_type}}
  }
  return CreateForm(form, true)
}
function EmailLoginScreen(){
  const form = {
      'body': [
          { type: 'FTTitle', display:false, title: 'Sign in to your account', field: 'form_title'},
          { type: 'FTTextInput', required:true, label: 'Email', placeholder: 'eg. ravi@abc.com', field: 'username', error:['Please enter a valid email address'], validator:['isEmail']},
          { type: 'FTTextInput', required:true, label: 'Password', field: 'password', inputType: 'password', link:{'text':'Forgot Password?', 'url':'/forgotPassword'}},
          { type: 'FTButton', title: 'Login', field: 'submit_button'},
          { type: 'FTLink', mesg1: 'Not a member?',  mesg2:'Sign Up', url:'/', field: 'submit_button'},
          { type: 'FTLink', mesg1: 'Email not verified?',  mesg2:'Verify', url:'/verifyAccount'}
      ],
      'submit':{ 'endpoint': API_CONFIG.API_BASE_URL + 'auth/initiateUserAuth', 'data': {'clientId': process.env.REACT_APP_AWS_COGNITO_CLIENTID}, 'navigate':'/home'}
  }
  return CreateForm(form, true)
}
function VerifyAccountScreen(){
  const form = {
      'body': [
          { type: 'FTTitle', display:false, title: 'Verify your account', field: 'form_title'},
          { type: 'FTLink', mesg1: 'Your account has been successfully verified. Please click the link below to login',  mesg2:'Click Here', url:'/login', display:'hidden'},
          { type: 'FTTextInput', required:true, label: 'Email', placeholder: 'eg. ravi@abc.com', field: 'username', error:['Please enter a valid email address'], validator:['isEmail']},
          { type: 'FTTextInput', required:true, label: 'Verification Code', field: 'code'},
          { type: 'FTButton', title: 'Verify', field: 'submit_button'},
          { type: 'FTLink', mesg1: 'Already a member?',  mesg2:'Login', url:'/login', field: 'submit_button'}
      ],
      'submit':{ 'endpoint': API_CONFIG.API_BASE_URL + 'auth/confirmSignUp', 'data': {'clientId': process.env.REACT_APP_AWS_COGNITO_CLIENTID}}
  }
  return CreateForm(form, true)
}
function ForgotPasswordScreen(){
  const form = {
      'body': [
          { type: 'FTTitle', display:false, title: 'Forgot Your Password', field: 'form_title'},
          { type: 'FTLink', mesg1: 'Check your email and click the link below to set your password',  mesg2:'Click Here', url:'/resetPassword', display:'hidden'},
          { type: 'FTTextInput', required:true, label: 'Enter email address', placeholder: 'eg. ravi@abc.com', field: 'username', error:['Please enter a valid email address'], validator:['isEmail']},
          { type: 'FTButton', title: 'Request Reset Instructions', field: 'submit_button'},
          { type: 'FTLink', mesg1: 'Not a member?',  mesg2:'Sign Up', url:'/signup'},
          { type: 'FTLink', mesg1: 'Email not verified?',  mesg2:'Resend Verification Code', url:'/resendVerificationCode'}
      ],
      'submit':{ 'endpoint': API_CONFIG.API_BASE_URL + 'auth/forgotPassword', 'data': {'clientId': process.env.REACT_APP_AWS_COGNITO_CLIENTID}}
  }
  return CreateForm(form, true)
}
function ResendVerificationCodeScreen(){
  const form = {
      'body': [
          { type: 'FTTitle', display:false, title: 'Resend Verification Code', field: 'form_title'},
          { type: 'FTLink', mesg1: 'Check your email and click the link below to verify your email',  mesg2:'Click Here', url:'/verifyAccount', display:'hidden'},
          { type: 'FTTextInput', required:true, label: 'Enter email address', placeholder: 'eg. ravi@abc.com', field: 'username', error:['Please enter a valid email address'], validator:['isEmail']},
          { type: 'FTButton', title: 'Send', field: 'submit_button'},
          { type: 'FTLink', mesg1: 'Not a member?',  mesg2:'Sign Up', url:'/signup'}
      ],
      'submit':{ 'endpoint': API_CONFIG.API_BASE_URL + 'auth/resendVerificationCode', 'data': {'clientId': process.env.REACT_APP_AWS_COGNITO_CLIENTID}}
  }
  return CreateForm(form, true)
}
//ResendVerificationCodeScreen
function ResetPasswordScreen(){
  const form = {
      'body': [
          { type: 'FTTitle', display:false, title: 'Set Your Password', field: 'form_title'},
          { type: 'FTLink', mesg1: 'Successfully updated your password. Click the link below to login.',  mesg2:'Click Here', url:'/login', display:'hidden'},
          { type: 'FTTextInput', required:true, label: 'Enter email address', placeholder: 'eg. ravi@abc.com', field: 'username', error:['Please enter a valid email address'], validator:['isEmail']},
          { type: 'FTTextInput', required:true, label: 'Verification Code', field: 'confirmationCode'},
          { type: 'FTTextInput', required:true, label: 'Password', helperText: 'Minimum 8 characters, atleast 1 number, 1 special character, 1 upper case, 1 lower case. ', field: 'password', error:['Please select a stronger password'], validator:['isPasswordValid'], inputType: 'password'},
          { type: 'FTTextInput', required:true, label: 'Confirm Password', placeholder: '', field: 'confirm_password', inputType: 'password', error:['Your password and confirm password does not match'], validator:['isEqual'], validator_fields:[['password']]},
          { type: 'FTButton', title: 'Reset Password', field: 'submit_button'},
          { type: 'FTLink', mesg1: 'Forgot your password?Click below to receive reset instructions',  mesg2:'Click here', url:'/forgotPassword'}
      ],
      'submit':{ 'endpoint': API_CONFIG.API_BASE_URL + 'auth/confirmForgotPassword', 'data': {'clientId': process.env.REACT_APP_AWS_COGNITO_CLIENTID}}
  }
  return CreateForm(form, true)
}



export {SignUpScreen, EmailLoginScreen, VerifyAccountScreen, ForgotPasswordScreen, ResetPasswordScreen, ResendVerificationCodeScreen}
