import logo from '../assets/images/logo.png'
  function LoadingOverlay(){
    return (
      <div className="flex items-center justify-center h-96">
        <svg className="w-16 h-16 text-indigo-600" viewBox="0 0 120 30" fill="currentColor">
          <circle cx="15" cy="15" r="15">
            <animate attributeName="r" from="15" to="15"
              begin="0s" dur="0.8s"
              values="15;9;15" calcMode="linear"
              repeatCount="indefinite" />
            <animate attributeName="fill-opacity" from="1" to="1"
              begin="0s" dur="0.8s"
              values="1;.5;1" calcMode="linear"
              repeatCount="indefinite" />
          </circle>
          <circle cx="60" cy="15" r="9" fillOpacity="0.3">
            <animate attributeName="r" from="9" to="9"
              begin="0s" dur="0.8s"
              values="9;15;9" calcMode="linear"
              repeatCount="indefinite" />
            <animate attributeName="fill-opacity" from="0.5" to="0.5"
              begin="0s" dur="0.8s"
              values=".5;1;.5" calcMode="linear"
              repeatCount="indefinite" />
          </circle>
          <circle cx="105" cy="15" r="15">
            <animate attributeName="r" from="15" to="15"
              begin="0s" dur="0.8s"
              values="15;9;15" calcMode="linear"
              repeatCount="indefinite" />
            <animate attributeName="fill-opacity" from="1" to="1"
              begin="0s" dur="0.8s"
              values="1;.5;1" calcMode="linear"
              repeatCount="indefinite" />
          </circle>
        </svg>
      </div>
    );
  }
function PreLoginHeader() {
    return (
      <header className="absolute inset-x-0 top-0 z-50">
          <nav aria-label="Global" className="flex items-center justify-between p-6 lg:px-8">
            <div className="flex lg:flex-1">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                  alt=""
                  src={logo}
                />
              </a>
            </div>
          </nav>
      </header>
      )
}
function PreLoginLogo({title}){
  return (
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          alt="Your Company"
          src={logo}
          className="mx-auto h-120 w-auto"
        />
        <h2 className="mt-5 text-center text-2xl/9 font-bold tracking-tight text-gray-900">
          {title}
        </h2>
    </div>
  )
}
function TopGradient(){
  return (
      <div
      aria-hidden="true"
      className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
       >
          <div  
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          />
     </div>
  )
}
function BottomGradient(){
  return (
    <div
    aria-hidden="true"
    className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
    >
        <div
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
          className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
        />
    </div>
  )
}
export { PreLoginHeader, TopGradient, BottomGradient, PreLoginLogo, LoadingOverlay};
