
import * as HIcons from '@heroicons/react/24/outline';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  function formatFileSize(size) {
    if (size < 1024) {
      return size + ' Bytes';
    } else if (size < 1048576) {
      return (size / 1024).toFixed(2) + ' KB';
    } else {
      return (size / 1048576).toFixed(2) + ' MB';
    }
  }
  function reverseFormatKeyName(formattedString) {
    if(typeof formattedString !== 'string' || !formattedString) 
      return '';
    const reversedString = formattedString
      .replace(/([A-Z])/g, ' $1') // Insert space before each uppercase letter
      .trim() // Remove leading space
      .toLowerCase() // Convert to lowercase
      .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize first letter of each word
    return reversedString;
  }

  function sortListByKeyOrder(list, keyOrder) {
    return list.sort((a, b) => {
      const keys = Object.keys(keyOrder);
      for (let key of keys) {
        if (a[key] !== b[key]) {
          return keyOrder[key] * (a[key] > b[key] ? 1 : -1);
        }
      }
      return 0;
    });
  }
  function capitalizeAndSpace(str) {
    if (!str) 
      return '';
    
    return str
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
  //Outputs March 17, 2023
  function readableDate(timestamp){
    const date = new Date(timestamp);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate;
  }
  function readableDateTime(timestamp) {
    const date = new Date(timestamp);
    const options = { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric', 
      hour: '2-digit', 
      minute: '2-digit', 
      second: '2-digit' 
    };
    const formattedDateTime = date.toLocaleString('en-US', options);
    return formattedDateTime;
  }
  function camelCaseToTitle(camelCaseString, removeLabel = false) {
    if (typeof camelCaseString !== 'string') {
      return '';
    }
    // Remove '_label' if removeLabel is true
    if (removeLabel) {
      camelCaseString = camelCaseString.replace(/_label$/, '');
    }
    // Replace underscores with spaces
    let result = camelCaseString.replace(/_/g, ' ');
    // Replace camelCase with spaces before capital letters
    result = result.replace(/([A-Z])/g, ' $1').trim();
    // Capitalize the first letter of each word
    result = result.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    return result;
  }
  //unixTimestamp is the time in milliseconds
  function timeAgo(unixTimestamp) {
    const now = new Date().getTime(); // Current time in milliseconds
    const timestamp = unixTimestamp; // Convert Unix timestamp to milliseconds
    const diffInSeconds = Math.floor((now - timestamp) / 1000); // Difference in seconds
  
    // Calculate difference
    const minute = 60;
    const hour = minute * 60;
    const day = hour * 24;
    const week = day * 7;
  
    let result;
  
    if (diffInSeconds < minute) {
      result = `${diffInSeconds}s ago`;
    } else if (diffInSeconds < hour) {
      result = `${Math.floor(diffInSeconds / minute)}m ago`;
    } else if (diffInSeconds < day) {
      result = `${Math.floor(diffInSeconds / hour)}h ago`;
    } else if (diffInSeconds < week) {
      result = `${Math.floor(diffInSeconds / day)}d ago`;
    } else {
      result = `${Math.floor(diffInSeconds / week)}w ago`;
    }
    return result;
  }
  function extractFromJSON(json, paths, exclude) {
    const jp = require('jsonpath');
    let path_obj = []
    paths.forEach(path => {
      path_obj = [...path_obj, ...jp.paths(json, path)]
    })
    let path_str = []
    path_obj.forEach(path => {
      const label = path[path.length - 1]
      if(exclude.includes(label))
        return
      path_str.push({'p': path, 'label': label, 'path': jp.stringify(path)})
    })
    let fval = {}
    path_str.forEach(e => {
      let val = jp.query(json, e.path)
      if(!fval[e.label])
        fval[e.label] = []
      fval[e.label].push(val)
    })
    return fval
  }
  
const DynamicHeroIcon = ({ icon, ...props }) => {
  //https://unpkg.com/browse/@heroicons/react@2.2.0/24/outline/
  const TheIcon = HIcons[icon];
  if (!TheIcon) {
    console.error(`Icon "${icon}" does not exist in @heroicons/react/outline`);
    return null;
  }
  return <TheIcon {...props} />;
};
function isObject(value) {
  return value && typeof value === 'object' && !Array.isArray(value);
}

  
  export {classNames, isObject, formatFileSize, sortListByKeyOrder, readableDateTime, reverseFormatKeyName, capitalizeAndSpace, readableDate, timeAgo, camelCaseToTitle, extractFromJSON, DynamicHeroIcon}