import { sendPostRequest, API_CONFIG } from '../helper/HttpHelper'
import { camelCaseToTitle, readableDate, classNames, sortListByKeyOrder } from './Helper';
import { useEffect, useContext, useState } from 'react'
import { AuthContext } from '../store/auth-context';
import { useNavigate } from 'react-router-dom';
import {Modal} from './Modal'
import {CreateForm} from './Form'
import { LoadingOverlay } from "../component/Common";

function BuildList({listJSON, FeedUI}) {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate()
  const {token} = useContext(AuthContext);
  const [list, setList] = useState([]);
  const [selectedItem, setSelectedItem] = useState('')
  const [actionIndex, setActionIndex] = useState('')
  const [open, setOpen] = useState(false)
  const [mesg, setMesg] = useState({title: '', content: '', action: '', button_text: ''})
  const [httpLoading, setHttpLoading] = useState(false);
 
  const handleLogout = () => {
    authCtx.logout()
    navigate('/login')
  }
  
  // To display the confirmation box
  function handleConfirmation(item, action_index) {
    console.log('BuildList - handleConfirmation - ' + item)
    setSelectedItem(item)   
    setActionIndex(action_index)  
    setMesg({title: listJSON.body.action[action_index].title, content: listJSON.body.action[action_index].content, action: handleApproval, button_text: listJSON.body.action[action_index].action_text})
    setOpen(true)
  }

  async function handleApproval(data, actionIndex){
    console.log('BuildList - handling approval - ' + data)
    try{
      let res = await sendPostRequest({'url': listJSON.body.action[actionIndex].endpoint, 'payload': {'data': data, 'meta':{token: token} }, 'setLoading':setHttpLoading, 'handleLogout':handleLogout}); 
      console.log(res)
      if(res.status === 'error'){
        console.error(res.message)
        return
      }
      setOpen(false)
      setSelectedItem('')
      window.location.reload()
    }catch(e){
      console.error(e)
    }
  }
  
  function getSelectedItem(){
    return selectedItem
  }
  function getActionIndex(){
    return actionIndex
  }
  function  renderTemplate(name){
    switch(name){
        case 'renderTableList':
            return renderTableList()
        case 'renderFeedList':
            return renderFeedList()
        case 'renderCompactList':
            return renderCompactList()
        default:
            return renderTableList()
    } 
  }        

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("BuildList - UseEffect - Fetching data from URL - " + listJSON.fetch.endpoint)
        const params = {
          method: listJSON?.fetch?.method,
          data: listJSON?.fetch?.data,
          meta: token?.eid ? {
            eid: token.eid,
            username: token.username,
            token: token,
            display_name: token.display_name
          } : {}
        };
    
        const res = await sendPostRequest({'url': listJSON.fetch.endpoint, 'payload': params, 'setLoading':setHttpLoading, 'handleLogout':handleLogout});
        console.log(res);
        if(listJSON.body.key_order)
          res.data = sortListByKeyOrder(res.data, listJSON.body.key_order)

        if (res.status === 'error') {
          console.error(res.message);
          return;
        }
        setList(res.data);
        console.log("Setting List - " + JSON.stringify(res.data))
      } catch (e) {
        console.error(e);
      }
    };
    fetchData()
  }, [])
  return renderTemplate(listJSON?.body?.template)
  

  function ListHeader(){

    return (
        <div className="border-b border-gray-200 bg-white px-4 py-3 mb-5 sm:px-6">
          <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-4">
            <h3 className="text-base font-semibold leading-6 text-gray-900">{listJSON.body.title}</h3>
            <p className="mt-1 text-sm text-gray-500">
              {listJSON.body.description}
            </p>
            </div>
            <div className="ml-4 mt-4 flex-shrink-0">
            </div>
          </div>
        </div>
    )
  }
  function ListFilter(){
    return (listJSON.body?.filter_form)?CreateForm(listJSON.body.filter_form, false, 'horizontal', setList):<></>
  }
  
  function renderTableList(){
      return (
        <div className="px-4 sm:px-6 lg:px-8">
          {(open) && <Modal title={mesg.title} content={mesg.content} action={mesg.action} getActionIndex={getActionIndex} button_text={mesg.button_text} getData={getSelectedItem} closeModal={() => setOpen(false)}></Modal>}
          <ListHeader />
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                {(httpLoading)?<LoadingOverlay/>:
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        {list && list.length > 0 && Object.keys(list[0].ATTR1).map((key, index) => (
                            (!listJSON.body?.exclude?.includes(key)) &&
                            <th key={index} scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                              {camelCaseToTitle(key)}
                            </th>
                        ))}
                      </tr>
      
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {list && list.length > 0 && list.map((item, index) => (
                        <tr key={index}>
                          {Object.keys(item.ATTR1).map((key) => (
                            (!listJSON.body?.exclude?.includes(key)) && (<td key={key} className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                              {listJSON.body.format?.[key] ? listJSON.body.format[key](item.ATTR1[key]): item.ATTR1[key]}
                            </td>)
                          ))}
                          {listJSON.body.action && listJSON.body.action.map((action,index) => (
                            action.exclude && item.ATTR1[action.exclude] === "true" ? null : (
                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0 space-x-2">
                                <a href="#" onClick={() => handleConfirmation(item, index)} className="text-indigo-600 hover:text-indigo-900">
                                  {action.action_text}
                                </a>
                              </td>
                            )
                          ))}
                        </tr>
                          
                      ))}
                    </tbody>
                  </table>
                }
              </div>
            </div>
          </div>
        </div>
      )
  }
  function renderFeedList(){
    function handleClick({url}) {
      navigate(url)
    }
    return (
        <>
        <ListHeader />
        {
            (httpLoading)?<LoadingOverlay/>:
              <ul
              role="list"
              className="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl"
              >
                {list && list.length > 0 && list.map((item, index) => (
                    <FeedUI item={item} feedJSON={listJSON} handleClick={handleClick} ></FeedUI>
                ))}
              </ul>
        }
        </>
    )
  }
  //{noun, modified_datetime, verb, object}
  function renderCompactList() {
    return (
      <div className="px-4 sm:px-6 lg:px-8">
        <ListHeader />
        <ListFilter />
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden md:rounded-lg">
                <ul className="divide-y divide-gray-200 bg-white">
                  {/* Example log entries */}
                  {list && list.length > 0 && list.map((item, index) => (
                    <FeedUI
                      key={index}
                      noun={item?.noun}
                      modified_datetime={item?.when}
                      verb={item?.verb}
                      object={item?.object}
                    />
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  

}
export { BuildList }