import { API_CONFIG, sendPostRequest } from '../../helper/HttpHelper'
import { CreateForm } from '../../component/Form'
import { camelCaseToTitle, readableDate, formatFileSize, classNames, timeAgo, isObject } from '../../component/Helper';
import { BuildList } from '../../component/List'
import { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../../store/auth-context'  
import { useNavigate, useParams } from 'react-router-dom';
import {Modal} from '../../component/Modal'


const statuses = {
    Complete: 'text-green-700 bg-green-50 ring-green-600/20',
    'In progress': 'text-gray-600 bg-gray-50 ring-gray-500/10',
    Archived: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
  }
function PdfFileElement({name, size, index}){
    return (
      <div className="flex items-center space-x-4" key={index}>
          <span className="flex-shrink-0">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path d="M14 2H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm1 18H5V4h10v16zm-9-9h6v2H6v-2zm0 4h6v2H6v-2z" fill="red"/>
          </svg>
          </span>
          <div className="flex-1 min-w-0">
              <p className="text-sm font-medium text-gray-900 truncate">
                {name}
              </p>
              <p className="text-sm text-gray-500 truncate">
                {formatFileSize(size)}
              </p>
          </div>
        </div>
    )
}
function LoanFeedComponent({item, feedJSON, handleClick}){
    console.log('LoanFeedComponent - ' + JSON.stringify(item))
    if(!item || !isObject(item) || !item.ATTR1 || !feedJSON || !feedJSON.body ){ 
        console.log("Returning Null ")
        return null
    }

    const renderChunks = (item) => {
        console.log('renderChunks - ' + JSON.stringify(item))
        const attributes = item.ATTR1 && Object.entries(item.ATTR1).filter(([key, value]) => !feedJSON.body.exclude.includes(key));
        console.log("Attributes - " + JSON.stringify(attributes)) 
        const chunkSize = 4;
        const chunks = [];
        for (let i = 0; i < attributes.length; i += chunkSize) {
          const chunk = attributes.slice(i, i + chunkSize);
          chunks.push(
            <div className="mt-1 grid grid-cols-4 text-xs gap-x-10 leading-5 text-gray-500" key={i}>
              {chunk.map(([key, value], index) => {
                const label = camelCaseToTitle(key, true);
                return label && key.includes("_file") ? <PdfFileElement name={value[0]['fname']} size={value[0]['size']}></PdfFileElement>: (
                  <p className="whitespace-nowrap" key={index}>
                    <span className='text-xs mr-2'>{label}:</span>
                    <span className='text-sm font-bold'>{feedJSON.body.format?.[key] ? feedJSON.body.format[key](value) : value + ' ' + (feedJSON.body.value_append?.[key] ?? '')}</span>
                  </p>
                );
              })}
            </div>
          );
        }
        return chunks;
      };
    return (
        <li key={item.sk} className="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6 hover:cursor-pointer" onClick={() => {handleClick({url:'/home/loan_detail/'+ item?.ATTR1?.id})}}>
                    <div className="min-w-0">
                        {<div className="flex items-start gap-x-3 mb-3" key="1">
                            <p className="text-sm font-semibold leading-6 text-gray-900">ID: {item?.ATTR1?.id}</p>
                            <p
                            className={classNames(
                                statuses['Sanctioned'],
                                'mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
                            )}
                            >
                            {camelCaseToTitle(item?.ATTR1?.status)}
                            </p>
                            <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500" key="4">
                            <p className="whitespace-nowrap italic">
                                Last Edited {readableDate(item.modified_datetime)}
                            </p>
                            <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                                <circle r={1} cx={1} cy={1} />
                            </svg>
                            <p className="truncate italic">Modified by {item?.ATTR1?.display_name}</p>
                            </div>
                        </div>}
                        <div className="mb-2">
                            {renderChunks(item)}
                        </div>
                    </div>
                </li>
    )  
}
function ListLoanScreen() {
    const listJSON = {
        'body':{
            'title':'Loan Applications',
            'subtitle':'All loan applications.',
            'format':{'performa_invoice_date':readableDate, 'target_date':readableDate },
            'value_append':{ 'funding_expected': 'Lakhs', 'performa_invoice_amount': 'Rs.',
                             'expected_loan_tenure_label':'months'
            },
            'action':{},
            'exclude':['eid', 'supplier','id', 'expected_loan_tenure'],
            'template':'renderFeedList' 
        },
        
        'fetch':{ 'endpoint': API_CONFIG.API_BASE_URL + 'retailer/list', 'method':'LoanAppId#'}
    }
    return BuildList({listJSON: listJSON, FeedUI: LoanFeedComponent})    
}
function ApplyLoanScreen(){
    const form = {
        'body': [
            { type: 'FTTitle',  field:'form_title', title: 'Trade Finance Loan Application', subtitle: 'Please upload your invoice and loan requirement details'},  
            { type: 'FTDynamicSelect', required: true, label: 'Supplier', field: 'supplier', fetch: {'endpoint':  API_CONFIG.API_BASE_URL + 'retailer/listCommon', 'method':'EnterpriseType#supplier', 'key': 'business_name'}},
            { type: 'FTTextInput', required: true, field: 'performa_invoice_no', label: 'Performa Invoice No' },
            { type: 'FTTextInput', required: true, inputType: 'date', field: 'performa_invoice_date', label: 'Performa Invoice Date'},
            { type: 'FTFileComponent', field:'invoice_file', placeholder: 'Upload your Performa Invoice'},
            
            { type: 'FTTextInput', required: true, field: 'performa_invoice_amount', label: 'Performa Invoice Amount(In Rs.)', placeholder: 'eg. 12345.56', error:['Please enter valid invoice amount'], validator:['isFloat']},
            { type: 'FTTextInput', required: true, field: 'funding_expected', label: 'Funding Expected Amount( In Lakhs)', placeholder: 'eg. 4', error:['Please enter valid funding amount'], validator:['isNumeric']},
            { type: 'FTSelect', label: 'Expected Loan Tenure( In Months)', field: 'expected_loan_tenure', 
                options:[{l:'1', k:'1'},{l:'2', k:'2'},{l:'3', k:'3'},{l:'4', k:'4'},{l:'5', k:'5'}]
            },
            { type: 'FTTextInput', required: true, inputType: 'date', field: 'target_date', label: 'Target Date for securing Loan'},
            { type: 'FTButton', title: 'Submit', field: 'submit_button'}
        ],
        'submit':{ 'endpoint': API_CONFIG.API_BASE_URL + 'retailer/add', 'method':'LoanAppId#{{aid}}', 'getpoint': API_CONFIG.API_BASE_URL + 'retailer/get'}
    }
    return CreateForm(form)
}
function LoanDetailScreen({loanId}) {
    const {token, loading, logout} = useContext(AuthContext);
    const [loanApplication, setLoanApplication] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const params = useParams()
    const navigate = useNavigate()
    if(!loanId){
     loanId = params.loanId
    }
    useEffect(() => {
        const fetchLoanApplication = async () => {
          let res = await sendPostRequest({'url': API_CONFIG.API_BASE_URL + 'retailer/get', 'payload': { 'method': `LoanAppId#${loanId}`, 'meta':{token: token} }, 'setLoading':setIsLoading, 'handleLogout':logout } ) 
          console.log(res.data)
          if(res.data && res.data){
            setLoanApplication(res.data)
            console.log(res.data)
          }
        }
        try{
          if(!loading)
            fetchLoanApplication()  
        }catch(e){
          console.error(e)
        }
      }, [])
    
    const feedJSON = {
        body:{
            'format':{
                  'performa_invoice_date':readableDate, 'target_date':readableDate },
                   'value_append':{ 'funding_expected': 'Lakhs', 'performa_invoice_amount': 'Rs.', 'expected_loan_tenure_label':'months'
                },
            'action':{},
            'exclude':['eid', 'supplier','id', 'expected_loan_tenure'],
        }
    }
    return (
     isLoading?<div>Loading...</div>:
        <>
            <div className='my-4 mb-6 border-b border-gray-100'>
                <LoanFeedComponent item={loanApplication} feedJSON={feedJSON}></LoanFeedComponent>
            </div>
            {true && <div className="grid grid-cols-2 gap-20">
                <div className="col-span-1">
                    <StatusUpdate loanApplication={loanApplication}></StatusUpdate>
                </div>   
                <div className="col-span-1">
                    <ActionComponent loanApplication={loanApplication}></ActionComponent>  
                </div>    
            </div>}
        </> 
    )
}
function StatusUpdate({loanApplication}) {
    return (
      <>
        <ul role="list" className="space-y-6">
          {loanApplication.activity && loanApplication.activity.length > 0 && loanApplication.activity.map((activityItem, activityItemIdx) => (
            <li key={activityItem.id} className="relative flex gap-x-4">
              <div
                className={classNames(
                  activityItemIdx === loanApplication.activity.length - 1 ? 'h-6' : '-bottom-6',
                  'absolute left-0 top-0 flex w-6 justify-center',
                )}
              >
                <div className="w-px bg-gray-200" />
              </div>
              <>
                  <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                    <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                  </div>
                  <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                    <span className="font-medium text-gray-900">{activityItem.name}</span> {activityItem.type}
                  </p>
                  <div className="flex-none py-0.5 text-xs leading-5 text-gray-500">
                    {timeAgo(activityItem.dateTime)}
                  </div>
                </>
            </li>
          ))}
        </ul>
      </>
    )
}
function PageHeader() {
    return (
    <div className="px-4 sm:px-0 pb-4 border-b border-gray-100">
        <h3 className="text-base font-semibold leading-7 text-gray-900">Loan Detail</h3>
        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Loan details and status.</p>
    </div>
    )
}
function ActionComponent({loanApplication}){
  const [open, setOpen] = useState(false)
  const [mesg, setMesg] = useState({content:'', title:''})
  const {token} = useContext(AuthContext);
    
  const navigate = useNavigate()
  const action = {
    'review':{
      'before': { title: 'Submit for Review',
                  content: 'Are you sure you want to submit the application for review?',
                  button: 'Submit'
                },
       'after': { title: 'Application Submitted',
                  content: 'Your application has been submitted for review.',
                  button:''
                }           
    },  
    'application':{
      'before': { title: 'Application',
                  content: 'Complete the application form',
                  button: 'Complete'
                },
       'after': { title: 'Application completed',
                  content: 'Your application has been completed',
                  button:''
                }           
    },  
  }
  const [application, setApplication] = useState((!loanApplication?.ATTR1?.application_status || loanApplication?.ATTR1?.application_status === 'info_pending')?action['application']['before']:action['application']['after'])
  const [review, setReview] = useState((!loanApplication?.ATTR1?.application_status || loanApplication?.ATTR1?.application_status === 'info_pending')?action['review']['before']:action['review']['after'])
  
  function handleReview(title, content, action ){
    setMesg({content:content, title:title, action:action})
    setOpen(true)
  }
  async function sendApplicationForReview(){ 
    console.log("Sending application for review")
    const resp = await sendPostRequest(API_CONFIG.API_BASE_URL + 'applicant/submitLoanApplication', { 'loanId': loanApplication.ATTR1.loan_id, 'token': token } )
    if(resp.status === 'success'){  
      setApplication(action['application']['after'])
      setReview(action['review']['after'])
    }
  }
  return (
    <div>
    {(open) && <Modal title={mesg.title} content={mesg.content} action={mesg.action} closeModal={() => setOpen(false)}></Modal>}
      <ul role="list" className="divide-y divide-gray-100">
          <li key="1" className="flex items-center justify-between gap-x-6 py-5">
            <div className="flex min-w-0 gap-x-4">
              <div className="min-w-0 flex-auto">
                <p className="text-sm font-semibold leading-6 text-gray-900">{application.title}</p>
                <p className="mt-1 truncate text-xs leading-5 text-gray-500">{application.content}</p>
              </div>
            </div>
              <a
                href={"loanApplication/" + loanApplication?.ATTR1?.loan_id + "/LoanRequirementScreen"}
                className={(application.button === '')?'hidden':"rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" }
              >
              {application.button}
            </a>
          </li>
          <li key="2" className="flex items-center justify-between gap-x-6 py-5">
            <div className="flex min-w-0 gap-x-4">
              <div className="min-w-0 flex-auto">
                <p className="text-sm font-semibold leading-6 text-gray-900">{review.title}</p>
                <p className="mt-1 truncate text-xs leading-5 text-gray-500">{review.content}</p>
              </div>
            </div>
              <a
                onClick={() => handleReview('Submit for Review', 'Are you sure you want to submit the application for review?', sendApplicationForReview)} 
                className={(review.button === '')?'hidden':"rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" }
              >
              {review.button}
            </a>
          </li>
      </ul>
    </div>
  )
}
 
export { ListLoanScreen, ApplyLoanScreen, LoanDetailScreen }