import { sendPostRequest, API_CONFIG } from '../../helper/HttpHelper'
import { camelCaseToTitle, readableDate } from '../../component/Helper';
import { BuildList } from '../../component/List'

function ApproveEnterpriseScreen(){
  //const [state, setState] = useState({open:false, selectedItem:''})
  const listJSON = {
      'body':{
        'title':'Enterprises',
        'description':'Approve new enterprise requests',
        'format':{'enterprise_type':camelCaseToTitle, 'created_datetime':readableDate, 'role':camelCaseToTitle},
        'action':[{ 'action_text':'Approve', 'params':'email', 'title':'Approve User!', 
                    'content':'By clicking, you agree to approve the user', 'endpoint': API_CONFIG.API_BASE_URL + 'admin/adminConfirmUserSignUp'}],
        'exclude':['eid']            
      },
      'fetch':{ 'endpoint': API_CONFIG.API_BASE_URL + 'admin/listUnconfirmedUsers'}
  }
  return BuildList({listJSON: listJSON})
}
function ListFinancierScreen(){
  const listJSON = {
      'body':{
        'title':'Financiers',
        'description':'All financier enterprises',
        'format':{'enterprise_type':camelCaseToTitle, 'create_datetime':readableDate, 'isConfirmedByAdmin':camelCaseToTitle, 'email_verified':camelCaseToTitle},  
        'exclude':['eid'] 
       },
      'fetch':{ 'endpoint': API_CONFIG.API_BASE_URL + 'admin/getAllEnterprisesOfType', 'method': {}, 'data': {'enterprise_type':'financier'}}
  }
  return BuildList({listJSON: listJSON})
}
function ListRetailerScreen(){
  const listJSON = {
      'body':{
        'title':'Retailers',
        'description':'All enterprises and their users.',
        'format':{'enterprise_type':camelCaseToTitle, 'create_datetime':readableDate, 'isConfirmedByAdmin':camelCaseToTitle, 'email_verified':camelCaseToTitle},  
        'action':[{ 'action_text':'Delete', 'params':['eid', 'enterprise_type'], 'title':'Delete Retailer!', 
                    'content':'By clicking, you agree to delete the enterprise ? This action will delete all users of this enterprise and associated data of the enterprise. This action cannot be undone!', 'endpoint': API_CONFIG.API_BASE_URL + 'admin/deleteEnterprise'}],
        'exclude':['eid'] 
      },
      
      'fetch':{ 'endpoint': API_CONFIG.API_BASE_URL + 'admin/getAllEnterprisesOfType', 'method': {}, 'data': {'enterprise_type':'retailer'}}
  }
  return BuildList({listJSON: listJSON})
}
function ListSupplierScreen(){
  const listJSON = {
      'body':{
        'title':'Suppliers',
        'description':'All enterprises and their users.',
        'format':{'enterprise_type':camelCaseToTitle, 'create_datetime':readableDate, 'isConfirmedByAdmin':camelCaseToTitle, 'email_verified':camelCaseToTitle},  
        'action':[{ 'action_text':'Delete', 'params':['eid', 'enterprise_type'], 'title':'Delete Retailer!', 
        'content':'By clicking, you agree to delete the enterprise ? This action will delete all users of this enterprise and associated data of the enterprise. This action cannot be undone!', 'endpoint': API_CONFIG.API_BASE_URL + 'admin/deleteEnterprise'}],
        'exclude':['eid'] 
      },
      'fetch':{ 'endpoint': API_CONFIG.API_BASE_URL + 'admin/getAllEnterprisesOfType', 'method': {}, 'data': {'enterprise_type':'supplier'}}
  }
  return BuildList({listJSON: listJSON})
}
export { ApproveEnterpriseScreen, ListRetailerScreen, ListSupplierScreen, ListFinancierScreen }